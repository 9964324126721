// @flow

/* eslint-disable max-len,react/jsx-tag-spacing */
import * as React from 'react';
import classNames from 'classnames';
import * as css from './Icon.scss';

type Props = {|
	type: string,
	className?: string,
	width?: number,
	height?: number,
	color?: string,
|};

/**
 * @link - https://material.io/tools/icons/?style=baseline
 * @return {XML}
 */
const Icon = ({ type, className, width, height, color, ...iconProps }: Props) => {
	let icon = null;
	const svgProps = {
		width,
		height,
		viewBox: `0 0 ${width || 24} ${height || 24}`,
		xmlns: 'http://www.w3.org/2000/svg',
	};

	switch (type) {
		case 'plus':
			icon = (
				<svg {...svgProps}>
					<g id="Group_1244" data-name="Group 1244" transform="translate(-234.5 -597.5)">
						<rect
							id="Rectangle_391"
							data-name="Rectangle 391"
							className="cls-1"
							width="4"
							height="24"
							transform="translate(244.5 597.5)"
							fill="#468aff"
						/>
						<rect
							id="Rectangle_392"
							data-name="Rectangle 392"
							className="cls-1"
							width="24"
							height="4"
							transform="translate(234.5 607.5)"
							fill="#468aff"
						/>
					</g>
				</svg>
			);
			break;
		case 'twitter':
			icon = (
				<svg {...svgProps}>
					<g id="twitter" transform="translate(0.4 -0.1)">
						<g id="Group_1785" data-name="Group 1785" transform="translate(0 0)">
							<ellipse
								id="Ellipse_13537"
								data-name="Ellipse 13537"
								className="cls-1"
								cx="20.5"
								cy="20"
								rx="20.5"
								ry="20"
								transform="translate(-0.4 0.1)"
								fill="#64a3df"
							/>
						</g>
						<path
							id="Icon_awesome-twitter"
							data-name="Icon awesome-twitter"
							className="cls-2"
							d="M18.423,7.537c.013.182.013.365.013.547A11.891,11.891,0,0,1,6.462,20.058,11.892,11.892,0,0,1,0,18.168a8.706,8.706,0,0,0,1.016.052,8.428,8.428,0,0,0,5.225-1.8A4.216,4.216,0,0,1,2.306,13.5a5.307,5.307,0,0,0,.795.065,4.451,4.451,0,0,0,1.107-.143A4.209,4.209,0,0,1,.834,9.3V9.244a4.238,4.238,0,0,0,1.9.534,4.215,4.215,0,0,1-1.3-5.628,11.962,11.962,0,0,0,8.677,4.4,4.751,4.751,0,0,1-.1-.964A4.212,4.212,0,0,1,17.289,4.71,8.285,8.285,0,0,0,19.96,3.693a4.2,4.2,0,0,1-1.85,2.319,8.436,8.436,0,0,0,2.423-.651,9.046,9.046,0,0,1-2.111,2.176Z"
							transform="translate(10.025 8.426)"
							fill="#fff"
						/>
					</g>
				</svg>
			);
			break;
		case 'whatsapp':
			icon = (
				<svg {...svgProps}>
					<path
						fill="#4aae20"
						d="M14249 6988a17 17 0 1 1 17 17 17 17 0 0 1-17-17zm7.185-.282a10.152 10.152 0 0 0 1.36 5.087l-1.443 5.271 5.391-1.417a10.2 10.2 0 0 0 4.863 1.241 10.175 10.175 0 1 0-10.175-10.182zm10.171 8.461a8.448 8.448 0 0 1-4.3-1.176l-.308-.183-3.2.838.857-3.119-.2-.32a8.458 8.458 0 1 1 7.163 3.96zm-4.275-12.836a2.842 2.842 0 0 0-.888 2.117 4.966 4.966 0 0 0 1.036 2.631 11.371 11.371 0 0 0 4.344 3.834 14.5 14.5 0 0 0 1.447.537 3.441 3.441 0 0 0 1.6.1 2.608 2.608 0 0 0 1.714-1.207 2.1 2.1 0 0 0 .148-1.208c-.065-.106-.232-.171-.488-.3s-1.5-.743-1.736-.827-.4-.129-.57.125-.659.827-.8 1-.3.19-.553.062a6.9 6.9 0 0 1-2.044-1.261 7.593 7.593 0 0 1-1.417-1.759c-.145-.255 0-.381.114-.518a7.268 7.268 0 0 0 .636-.872.467.467 0 0 0-.023-.446c-.06-.125-.57-1.375-.784-1.884-.183-.438-.369-.438-.518-.438h-.053c-.148-.008-.316-.008-.487-.008a.935.935 0 0 0-.68.32z"
						transform="translate(-14248.999 -6971)"
					/>
				</svg>
			);
			break;
		case 'facebook':
			icon = (
				<svg {...svgProps}>
					<path
						fill="#154be0"
						d="M17.071.349a17.062 17.062 0 0 0-2.847 33.886V20.982h-4.118v-4.77h4.118V12.7c0-4.08 2.492-6.3 6.133-6.3a33.985 33.985 0 0 1 3.677.187v4.265h-2.525c-1.979 0-2.361.94-2.361 2.321v3.037h4.723l-.616 4.769h-4.107v13.37a17.063 17.063 0 0 0-2.077-34z"
						transform="translate(0 -.349)"
					/>
				</svg>
			);
			break;

		case 'share':
			icon = (
				<svg {...svgProps}>
					<g id="share" transform="translate(0.33 -0.337)">
						<rect
							id="Rectangle_20"
							data-name="Rectangle 20"
							className="cls-1"
							width="33"
							height="32"
							transform="translate(-0.33 0.337)"
							fill="#468aff"
							opacity="0"
						/>
						<path
							id="Path_1252"
							data-name="Path 1252"
							className="cls-2"
							fill="#468aff"
							d="M23.559,19.17a4.042,4.042,0,0,0-2.83,1.159L10.085,15.586v-.9L20.729,9.926a4.042,4.042,0,1,0-1.213-2.883V7.5L8.873,12.244a4.042,4.042,0,1,0,0,5.767l10.644,4.756v.445a4.042,4.042,0,1,0,4.042-4.042Zm0-13.474a1.347,1.347,0,1,1-1.347,1.347A1.347,1.347,0,0,1,23.559,5.7ZM6.043,16.475A1.347,1.347,0,1,1,7.39,15.127,1.347,1.347,0,0,1,6.043,16.475Zm17.516,8.084a1.347,1.347,0,1,1,1.347-1.347A1.347,1.347,0,0,1,23.559,24.559Z"
							transform="translate(0.694 1.041)"
						/>
					</g>
				</svg>
			);
			break;
		case 'accessibility':
			icon = (
				<svg {...svgProps}>
					<g id="נגישות" transform="translate(-27.002 -22.001)">
						<rect
							id="Rectangle_299"
							width="34"
							height="34"
							fill="#e03115"
							rx="17"
							transform="translate(27.002 22.001)"
						/>
						<g id="XMLID_28_" transform="translate(-10.376 30.001)">
							<path
								id="XMLID_31_"
								d="M178.532 3.418a1.709 1.709 0 1 0-1.709-1.709 1.709 1.709 0 0 0 1.709 1.709z"
								className="cls-2"
								transform="translate(-124.881)"
								fill="#fff"
							/>
							<path
								id="XMLID_30_"
								d="M56.292 204.681a3.777 3.777 0 1 1-5.149-4.5v-1.6a5.281 5.281 0 1 0 6.313 7.269l-.6-1.172z"
								className="cls-2"
								transform="translate(0 -191.581)"
								fill="#fff"
							/>
							<path
								id="XMLID_29_"
								d="M191.737 128.291l-2.289-4.5a1 1 0 0 0-.894-.549h-2.931v-.668h2.523a.789.789 0 0 0 .753-.753.752.752 0 0 0-.753-.753h-2.523V118.8a1.5 1.5 0 1 0-3.01 0v4.912a1.535 1.535 0 0 0 1.535 1.535h3.791l2.01 3.952a1 1 0 1 0 1.788-.91z"
								className="cls-2"
								transform="translate(-130.467 -113.162)"
								fill="#fff"
							/>
						</g>
					</g>
				</svg>
			);
			break;
		default:
			break;
	}

	return (
		<i className={classNames(css.icon, className)} {...iconProps}>
			{icon}
		</i>
	);
};

Icon.defaultProps = {
	className: '',
	width: 24,
	height: 24,
	color: '#111',
};

export { Icon };
