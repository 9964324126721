// @flow

export type ActionName = {
	START: string,
	SUCCESS: string,
	FAIL: string,
};

export const SENT_FEEDBACK_INFO = {
	START: 'SENT_FEEDBACK_INFO.START',
	SUCCESS: 'SENT_FEEDBACK_INFO.SUCCESS',
	FAIL: 'SENT_FEEDBACK_INFO.FAIL',
};

export const CREATE_ITEM = {
	START: 'CREATE_ITEM.START',
	SUCCESS: 'CREATE_ITEM.SUCCESS',
	FAIL: 'CREATE_ITEM.FAIL',
};

export const SET_POP_UP = 'SET_POP_UP';
