export const dataLayerEvents = {
	/**
	 * @param action {String}
	 * @param label {String}
	 */
	onSendForm: () =>
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_event',
			Category: 'Lead form',
			Action: 'Form sent',
			Label: 'Osem Balanced kitchen',
		}),

	onShareClick: shareName =>
		window.dataLayer &&
		window.dataLayer.push({
			event: 'share_events',
			Category: 'Social',
			Action: 'Share From Site Head',
			Label: `${shareName}`,
		}),
};
