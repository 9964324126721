// @flow
export const generateCssTransitionMap = (module: any, name?: string) => {
	if (name) {
		return {
			appear: module[`${name}Appear`],
			appearActive: module[`${name}AppearActive`],
			appearDone: module[`${name}AppearDone`],
			enter: module[`${name}Enter`],
			enterActive: module[`${name}EnterActive`],
			enterDone: module[`${name}EnterDone`],
			exit: module[`${name}Exit`],
			exitActive: module[`${name}ExitActive`],
			exitDone: module[`${name}ExitDone`],
		};
	}

	return {
		appear: module.appear,
		appearActive: module.appearActive,
		appearDone: module.appearDone,
		enter: module.enter,
		enterActive: module.enterActive,
		enterDone: module.enterDone,
		exit: module.exit,
		exitActive: module.exitActive,
		exitDone: module.exitDone,
	};
};
