/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Responsive } from 'client/components/common';
import _ from 'lodash';
import { dataLayerEvents } from 'common/utils/gtag';
import formBg from 'assets/client/images/form-screen-bg.jpg';
import formBGMobile from 'assets/client/images/form-screen-bg_mobile.png';
// import documentDownload from 'assets/client/document.docx';
import documentDownload2 from 'assets/client/קבוצת ידיעות - תקנון פעילות קיימות סופי -24.5.2021.pdf';
import * as css from './Intro.scss';

type Props = {
	onCreateLead: Function,
	className?: string,
};

type State = {
	form: {
		fullName: '',
		address: '',
		phone: '',
		email: '',
		text: '',
		checkbox: boolean,
	},
	errors: {
		fullNameError: string,
		addressError: string,
		phoneError: string,
		emailError: string,
		textError: string,
		checkboxError: boolean,
	},
	isLoadingData: boolean,
};

class FormScreen extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {
		form: {
			fullName: '',
			address: '',
			phone: '',
			email: '',
			text: '',
			checkbox: false,
		},
		errors: {
			fullNameError: '',
			addressError: '',
			phoneError: '',
			emailError: '',
			textError: '',
			checkboxError: false,
		},
		isLoadingData: false,
	};

	handleInputChange = e => {
		const info = { ...this.state.form, [e.target.name]: e.target.value };
		const { error } = e.currentTarget.dataset;
		const errorInfo = { ...this.state.errors, [error]: '' };
		this.setState({
			form: info,
			errors: errorInfo,
		});
	};

	handleCheckboxChange = e => {
		const info = { ...this.state.form, [e.target.name]: e.target.checked };
		const { error } = e.currentTarget.dataset;
		const errorInfo = { ...this.state.errors, [error]: false };
		this.setState({
			form: info,
			errors: errorInfo,
		});
	};

	onSubmit = async e => {
		e.preventDefault();
		const { onCreateLead } = this.props;
		const { form } = this.state;
		const { fullName, address, phone, email, text } = form;
		const fullNameRegExp = /^[a-zA-Z\u0590-\u05fe\s]+$/i;
		const validfullName = fullNameRegExp.test(form.fullName.toLocaleLowerCase());
		const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const validEmail = emailRegExp.test(form.email.toLocaleLowerCase());
		const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
		const validPhone = phoneRegExp.test(form.phone.toLocaleLowerCase());

		const errors = {};

		if (!form.fullName) {
			errors.fullNameError = 'יש למלא שם מלא';
		}

		if (!form.address) {
			errors.addressError = 'יש למלא כתובת';
		}

		if (!form.phone) {
			errors.phoneError = 'יש למלא טלפון';
		}

		if (!form.email) {
			errors.emailError = 'יש למלא דוא"ל';
		}

		if (!form.text) {
			errors.textError = 'יש למלא כתבו את הרעיון שלכם';
		}

		if (!form.checkbox) {
			errors.checkboxError = true;
		}

		if (form.fullName && !validfullName) {
			errors.fullNameError = 'יש למלא שם מלא תקין';
		}

		if (form.email && !validEmail) {
			errors.emailError = 'יש למלא דוא"ל תקין';
		}

		if ((form.phone && !validPhone) || form.phone.length > 10) {
			errors.phoneError = 'יש למלא טלפון תקין';
		}

		if (_.isEmpty(errors)) {
			const data = {
				fullName,
				address,
				phone,
				email,
				text,
			};
			this.setState({
				isLoadingData: true,
			});
			await onCreateLead(data);
			dataLayerEvents.onSendForm();
			this.setState({
				form: {
					fullName: '',
					address: '',
					phone: '',
					email: '',
					text: '',
					checkbox: false,
				},
				errors: {
					fullNameError: '',
					addressError: '',
					phoneError: '',
					emailError: '',
					textError: '',
					checkboxError: false,
				},
				isLoadingData: false,
			});
		} else {
			const errorsInfo = { ...this.state.errors, ...errors };
			if (errors.checkboxError) {
				alert('עליך לאשר את הסכמתך לתקנון על מנת להמשיך');
			}
			this.setState({
				errors: errorsInfo,
			});
		}
	};

	render() {
		const isTabletL = Responsive.isMatching(Responsive.TABLET_L);
		const isTabletP = Responsive.isMatching(Responsive.TABLET_P);
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const { className } = this.props;
		const bg = isDesktop || isTabletL || isTabletP ? formBg : formBGMobile;
		const { isLoadingData } = this.state;
		const { fullName, address, phone, email, text, checkbox } = this.state.form;
		const { fullNameError, addressError, phoneError, emailError, textError, checkboxError } = this.state.errors;

		return (
			<div className={classNames(css.formScreen, className)} style={{ backgroundImage: `url(${bg})` }}>
				<div className={css.formWrapper}>
					<div className={css.field}>
						<input
							type="text"
							name="fullName"
							id="fullName"
							placeholder={fullNameError === '' ? 'שם מלא' : fullNameError}
							onChange={this.handleInputChange}
							value={fullName}
							data-error="fullNameError"
							className={classNames(fullNameError !== '' && css.error)}
						/>
					</div>
					<div className={css.field}>
						<input
							type="text"
							name="address"
							id="address"
							placeholder={addressError === '' ? 'כתובת' : addressError}
							onChange={this.handleInputChange}
							value={address}
							data-error="addressError"
							className={classNames(addressError !== '' && css.error)}
						/>
					</div>
					<div className={css.field}>
						<input
							type="text"
							name="phone"
							id="phone"
							placeholder={phoneError === '' ? 'טלפון' : phoneError}
							onChange={this.handleInputChange}
							value={phone}
							data-error="phoneError"
							className={classNames(phoneError !== '' && css.error)}
						/>
					</div>
					<div className={css.field}>
						<input
							type="text"
							name="email"
							id="email"
							placeholder={emailError === '' ? 'דוא"ל' : emailError}
							onChange={this.handleInputChange}
							value={email}
							data-error="emailError"
							className={classNames(emailError !== '' && css.error)}
						/>
					</div>
					<div className={classNames(css.field, css.fieldLong)}>
						<textarea
							name="text"
							id="text"
							placeholder={
								textError === ''
									? 'בני ובנות 13 ומעלה! ספרו לנו מה הרעיון שלכם וציינו את גילכם'
									: textError
							}
							onChange={this.handleInputChange}
							value={text}
							data-error="textError"
							className={classNames(textError !== '' && css.error)}
						></textarea>
					</div>
					<div className={classNames(css.field, css.fieldLong, css.checkboxRowWrapper)}>
						<label className={css.checkboxRow}>
							<input
								type="checkbox"
								id="checkbox"
								name="checkbox"
								value={checkbox}
								onChange={this.handleCheckboxChange}
								data-error="checkboxError"
							/>
							<div
								className={classNames(
									css.controll,
									checkboxError === true && css.error,
									checkbox && css.active
								)}
							></div>
							<p>
								קראתי, הבנתי ואני מסכים{' '}
								<a href={documentDownload2} rel="noopener noreferrer" target="_blank">
									לתקנון הפעילות
								</a>{' '}
								ולמסירת הפרטים למאגר הצרכנים של קבוצת אסם, בהתאם{' '}
								<a
									href="https://www.osem-nestle.co.il/privacy-page"
									rel="noopener noreferrer"
									target="_blank"
								>
									למדיניות הפרטיות של אסם
								</a>
								. כמו כן, הנני מסכים בזאת, לקבלת הטבות, מבצעים ועדכונים מקבוצת אסם, בקשר למותגים אסם,
								נסטלה, נסקפה, מטרנה, גרבר,צבר, בונזו'ר, טבעול, ויטמינצ'יק, סטארבקס, קיטקט, סמארטיס,
								בישולים ופורינה, בכל אחד מערוצי התקשורת
							</p>
						</label>
					</div>
					<div className={classNames(css.btnRow)}>
						<a href="#" onClick={this.onSubmit}>
							<span>שלח</span> &gt;&gt;
						</a>
					</div>
					<div className={classNames(css.preloaderWrapper, isLoadingData && css.show)}>
						<svg viewBox="25 25 50 50" className={classNames(css.preloader)}>
							<circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
						</svg>
					</div>
				</div>
				<div className={css.privacy}>
					<a
						href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
						rel="noopener noreferrer"
						target="_blank"
					>
						מדיניות פרטיות
					</a>
					<a
						href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
						rel="noopener noreferrer"
						target="_blank"
					>
						תנאי שימוש
					</a>
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({});
const mapDispatch = {};

export default connect(mapState, mapDispatch)(FormScreen);
