// @flow

import * as React from 'react';
import classNames from 'classnames';

import { SHARE_CONSTANTS } from 'common/constants';
import { share } from 'client/utils/share';
import { dataLayerEvents } from 'common/utils/gtag';

import { Icon } from '../Icon';

import * as css from './Socialls.scss';

type Props = {
	className?: string,
};

type State = {
	isOpen: boolean,
};

export default class Socials extends React.Component<Props, State> {
	containerRef: { current: null | HTMLDivElement } = React.createRef();

	static defaultProps = {
		className: '',
	};

	state = {};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		const type = e.currentTarget.id;
		dataLayerEvents.onShareClick(type);
		share(type);
	};

	render() {
		const { className } = this.props;

		return (
			<div className={classNames(css.socials, className)} ref={this.containerRef}>
				<div className={css.btnsWrap}>
					<button
						type="button"
						className={css.whatsAppBtn}
						id={SHARE_CONSTANTS.WHATSAPP}
						onClick={this.onShare}
					>
						<Icon color="#44c9f6" type="whatsapp" width={34} height={34} />
					</button>
					<button
						type="button"
						className={css.facebookBtn}
						id={SHARE_CONSTANTS.FACEBOOK}
						onClick={this.onShare}
					>
						<Icon color="#44c9f6" type="facebook" width={34} height={34} />
					</button>
				</div>
			</div>
		);
	}
}
