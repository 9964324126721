// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';

import popUp, { type PopUp } from './pop-up';

export type Store = {
	+popUp: PopUp,
};

const reducer: Reducer<Store, Action<{ type: string, payload: any }>> = combineReducers({
	popUp,
});

export default reducer;
