// @flow
// import * as actions from 'client/constants/actions';

import * as actions from 'client/constants/actions';
import * as api from 'api';

import { apiRequestAction } from './helpers/api-request';

export type Action = { type: string, payload?: any };
// export type ThunkAction = (dispatch: Dispatch, getState: () => Store) => Promise<any>;
export type Dispatch = (action: Action | ThunkAction | Promise<Action>) => Promise<void>;

export function sentFeedbackInfo(params: any): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.SENT_FEEDBACK_INFO,
			request: api.sentEmail,
			params: { ...params },
		});

		return dispatch(requestAction);
	};
}

export function createItem<I: AbstractItem>(params: CreateItemParams<I>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.CREATE_ITEM,
			request: api.createItemLead,
			params,
		});

		return dispatch(requestAction);
	};
}
