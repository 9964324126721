// @flow
import * as React from 'react';
import classNames from 'classnames';
import { Responsive } from 'client/components/common';

import leadCreatedScreenBg from 'assets/client/images/closed-activity-screen-bg.jpg';
import leadCreatedScreenBgMobile from 'assets/client/images/closed-activity-screen-bg_mobile.png';
import * as css from './Intro.scss';

type Props = {
	className?: string,
};

const ClosedActivityScreen = ({ className }: Props) => {
	const isTabletL = Responsive.isMatching(Responsive.TABLET_L);
	const isTabletP = Responsive.isMatching(Responsive.TABLET_P);
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const bg = isDesktop || isTabletL || isTabletP ? leadCreatedScreenBg : leadCreatedScreenBgMobile;
	return (
		<div
			className={classNames(css.closedActivityScreen, className)}
			style={{ backgroundImage: `url(${bg})` }}
		></div>
	);
};

ClosedActivityScreen.defaultProps = {
	className: '',
};

export default ClosedActivityScreen;
