import _ from 'lodash';
import translations from './translations.json';

/**
 * @class Translator
 * saves locale for translation context
 */
class Translator {
	constructor(locale = 'he') {
		this.locale = locale;
	}

	/**
	 * translate - translate strings, optionally with parameters
	 * @see {@link https://lodash.com/docs/4.17.10#template}
	 *
	 * @param  {string} path = '' path in translations object
	 * @param  {object} options   template data (optional)
	 * @return {string} parsed template
	 */
	translate = (path, options) => {
		path = `${this.locale}.${path}`;

		/**
		 * @desc template settings for translaton
		 * @see {@link https://lodash.com/docs/4.17.10#templateSettings}
		 */
		const translateTemplateSettings = {
			interpolate: /{{([\s\S]+?)}}/g,
		};

		let result = _.get(translations, path, path);
		if (_.isObject(options)) {
			const compiled = _.template(result, translateTemplateSettings);
			result = compiled(options);
		}

		return result;
	};
}

export const translator = new Translator();
export default translator.translate;
