// @flow
import * as React from 'react';
import classNames from 'classnames';
import { Responsive } from 'client/components/common';

import thanksScreenBg from 'assets/client/images/thanks-screen-bg.jpg';
import thanksScreenBgMobile from 'assets/client/images/thanks-screen-bg_mobile.png';
import * as css from './Intro.scss';

type Props = {
	className?: string,
};

const ThanksScreen = ({ className }: Props) => {
	const isTabletL = Responsive.isMatching(Responsive.TABLET_L);
	const isTabletP = Responsive.isMatching(Responsive.TABLET_P);
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const bg = isDesktop || isTabletL || isTabletP ? thanksScreenBg : thanksScreenBgMobile;
	return <div className={classNames(css.thanksScreen, className)} style={{ backgroundImage: `url(${bg})` }}></div>;
};

ThanksScreen.defaultProps = {
	className: '',
};

export default ThanksScreen;
