// @flow
import translate from './translate';

export { translate };
export { thousandsSeparator } from './thousandSeperator';
export { detectIE } from './detect-ie';
export { componentDidMount, componentDidUpdate, componentWillUnmount } from './hooks';
export { parseURL } from './parse-url';
export { parseSearch } from './parse-react-router-search';
export { fileLink } from './file-link';
export { getProviderName } from './get-provider-name';
export { scrollToInputError } from './scroll-to-input-error';
export { generateCssTransitionMap } from './generate-css-transition-map';
export {
	isBrowserVisible,
	isDocumentFocused,
	addVisibilityChangeListener,
	whenBrowserVisible,
} from './browser-visibility';
export { shuffle } from './shuffle-array';
export { assets } from './client-assets';
export const sleep = (ms: number) => new Promise<void>(res => setTimeout(res, ms));
