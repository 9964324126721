// @flow

import * as actions from 'client/constants/actions';

export type PopUp = {
	id: string,
	data?: any,
};

export type Action = {
	type: string,
	payload: PopUp,
};

const initialState: PopUp = {
	id: '',
	data: null,
};

export default function popUpReducer(state: PopUp = initialState, { type, payload }: Action) {
	switch (type) {
		case actions.SET_POP_UP:
			return {
				...payload,
			};

		default:
			return state;
	}
}
