// @flow

import * as React from 'react';
import classNames from 'classnames';
// import { connect } from 'react-redux';
// import _ from 'lodash';

// import type { State } from 'client/reducers';
// import { ROOT_ROUTE } from 'client/constants';
import ynetLogoSrc from 'assets/client/images/logo.svg';
import partnerLogoSrc from 'assets/client/images/partner-logo.png';
import partnerLogo2Src from 'assets/client/images/partner-logo2.png';

import AccessibilityIcon from '../AccessibilityIcon';

import Socialls from '../Socialls';

import * as css from './Header.scss';

type Props = {
	className?: string,
};

const Header = ({ className }: Props) => {
	const ynetLink = 'https://www.ynet.co.il/';
	const partnerLink = 'https://www.osem-nestle.co.il/';
	const partnerLink2 = 'https://www.frogi.co.il/';

	return (
		<div className={classNames(css.header, className)} id="header">
			<div className={css.headerWrapper}>
				<div className={css.rightSide}>
					<a href={partnerLink} className={css.partnerLogo} rel="noopener noreferrer" target="_blank">
						<img src={partnerLogoSrc} alt="" />
					</a>
					<a href={ynetLink} className={css.ynetLogo} rel="noopener noreferrer" target="_blank">
						<img src={ynetLogoSrc} alt="" />
					</a>
					<a href={partnerLink2} className={css.partnerLogo2} rel="noopener noreferrer" target="_blank">
						<img src={partnerLogo2Src} alt="" />
					</a>
				</div>
				<div className={css.leftSide}>
					<Socialls />
					<AccessibilityIcon />
				</div>
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
};

// const mapState = (state: State) => ({
// 	data: _.get(state, 'page.data', {}),
// });

export { Header };
