// @flow
import { CAN_USE_DOM } from 'common/constants';

let stateKey;
let eventKey;
const keys = {
	hidden: 'visibilitychange',
	webkitHidden: 'webkitvisibilitychange',
	mozHidden: 'mozvisibilitychange',
	msHidden: 'msvisibilitychange',
};

Object.keys(keys).forEach(key => {
	if (CAN_USE_DOM && !stateKey && key in document) {
		stateKey = key;
		eventKey = keys[key];
	}
});

// flow-disable-next-line
export const isBrowserVisible = () => CAN_USE_DOM && !document[stateKey];
export const isDocumentFocused = () => CAN_USE_DOM && document.hasFocus();
export const addVisibilityChangeListener = (cb: (e: Event) => void) => {
	if (CAN_USE_DOM) {
		document.addEventListener(eventKey, cb);
	}
};
export const whenBrowserVisible = () => {
	return new Promise<any>(resolve => {
		if (isDocumentFocused()) {
			resolve();
		} else {
			const listener = () => {
				window.removeEventListener('focus', listener);
				resolve();
			};
			window.addEventListener('focus', listener);
		}
	});
};
