// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { Store } from 'client/reducers';
// import _ from 'lodash';
import Intro from './Intro';

const mapState = (state: Store) => ({});

const mapDispatch = {};

export default (connect(mapState, mapDispatch)(Intro): React.AbstractComponent<{}>);
