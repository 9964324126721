// @flow
export function apiRequestAction<P, R: Function>({ action, request, params }: ApiRequestAction<P, R>): ThunkAction {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({ type: action.START, payload: params });

			const response = await request(params);

			if (response.success) {
				dispatch({ type: action.SUCCESS, payload: response.data });
				return response.data;
			}

			throw new Error(response.error);
		} catch (e) {
			const msg = e.message;
			dispatch({ type: action.FAIL, payload: msg });
		}

		return null;
	};
}
